import {useAuth, useFirestore} from "reactfire";
import {useEffect, useState} from "react";
import {signInWithEmailLink} from "../../firebase/auth-api";
import {createUserDocument} from "../../firebase/firestore-api";
import {Spin} from "antd";
import {useLocation} from "wouter";

export default function LoginConfirm(){

    const auth = useAuth();
    const firestore = useFirestore();
    const [loading, setLoading] = useState(false);
    const [, setLocation] = useLocation();

    useEffect(() => {
       if(auth.isSignInWithEmailLink(window.location.href)) {
           let email = window.localStorage.getItem('emailForSignIn');
           while (!email) {
               // User opened the link on a different device. To prevent session fixation
               // attacks, ask the user to provide the associated email again. For example:
               email = window.prompt('Įveskite el. paštą, kad jį patvirtintumėte.');
           }
           signInWithEmailLink(auth, email, window.location.href).then((credential) => {
               if (credential.additionalUserInfo?.isNewUser) {
                   createUserDocument(firestore, credential.user!!.uid).then(() => {
                       setLoading(false);
                       setLocation("/");
                   });
               }
           })
               .catch(reason => console.error(reason))
       }
    }, [auth, firestore, setLocation])

    return(
        <Spin size="large" spinning={loading}/>
    )

}
