import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
// import reportWebVitals from './reportWebVitals';
import {FirebaseAppProvider} from 'reactfire';
import {firebaseConfig} from "./firebase/config";
import {initializeFirebase} from "./firebase/utils";
import {Spin} from "antd";


initializeFirebase(firebaseConfig).then((firebaseApp) => {
    ReactDOM.render(
        <React.StrictMode>

            <FirebaseAppProvider firebaseApp={firebaseApp} suspense={true}>
                <Suspense fallback={<Spin size="large" />}>
                <App/>
                </Suspense>
            </FirebaseAppProvider>
        </React.StrictMode>,
        document.getElementById('root')
    );
})

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
