import {PlayerDoc} from "../../firebase/firestoreTypes";
import {ResponsiveLine} from "@nivo/line"
import { ResponsiveBar } from '@nivo/bar'
import React, {useEffect, useState} from "react";

type ItemType = {
    id: string | number
    data: {
        x: number | string
        y: number | string
    }[]
}
type DataType = ItemType[]

const toBarFormat = (data: DataType) => data.map(party => (
    { id: party.id, "Palaikymas": party.data[0].y }
))

export default function Chart({players}: { players: PlayerDoc[]}) {

    const [data, setData] = useState<DataType>([]);
    useEffect(() => {
        // Reshape data to conform specified format
        const d = players.map(player => {
            const item = {id: player.partyName, data: []} as ItemType;
            for (const [i, t] of player.totalsHistory.entries()) {
                item.data.push({x: i + 1, y: t * 100});
            }
            return item;
        }) as DataType;
        setData(d);
    }, [players])

    return (
        <div style={{height: 400}}>
            { players.every(p => p.totalsHistory.length === 1) ?
                <ResponsiveBar
                    data={toBarFormat(data)}
                    margin={{top: 50, right: 110, bottom: 50, left: 60}}
                    indexBy="id"
                    keys={["Palaikymas"]}
                    groupMode="grouped"
                    axisLeft={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: 'Palaikymas, %',
                        legendOffset: -40,
                        legendPosition: 'middle'
                    }}
                    labelFormat=" >-.2f"
                    enableLabel={false}
                    /* @ts-ignore */
                    colorBy="index"
                    tooltipFormat=" >-.2f"
                />
                :
                <ResponsiveLine
                    data={data}
                    margin={{top: 50, right: 110, bottom: 50, left: 60}}
                    xScale={{type: 'point'}}
                    gridXValues={[1, 2, 3]}
                    yScale={{type: 'linear', min: 0, max: 100, stacked: false, reverse: false}}
                    yFormat=" >-.2f"
                    axisTop={null}
                    axisRight={null}
                    axisBottom={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: 'Ėjimai',
                        legendOffset: 36,
                        legendPosition: 'middle'
                    }}
                    axisLeft={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: 'Palaikymas, %',
                        legendOffset: -40,
                        legendPosition: 'middle'
                    }}
                    pointSize={10}
                    pointColor={{theme: 'background'}}
                    pointBorderWidth={2}
                    pointBorderColor={{from: 'serieColor'}}
                    pointLabelYOffset={-12}
                    useMesh={false}
                    enableSlices={'x'}
                    legends={[
                        {
                            anchor: 'bottom-right',
                            direction: 'column',
                            justify: false,
                            translateX: 100,
                            translateY: 0,
                            itemsSpacing: 0,
                            itemDirection: 'left-to-right',
                            itemWidth: 80,
                            itemHeight: 20,
                            itemOpacity: 0.75,
                            symbolSize: 12,
                            symbolShape: 'circle',
                            symbolBorderColor: 'rgba(0, 0, 0, .5)',
                            effects: [
                                {
                                    on: 'hover',
                                    style: {
                                        itemBackground: 'rgba(0, 0, 0, .03)',
                                        itemOpacity: 1
                                    }
                                }
                            ]
                        }
                    ]}
                />
            }
        </div>
    )
}
