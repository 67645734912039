import React from 'react';
import styles from './Home.module.css';
import { Layout } from 'antd';
import { Route, Switch } from 'wouter';
import LoginLayout from '../components/LoginLayout/LoginLayout';
import LoginLayoutNEW from '../components/LoginLayout/LoginLayoutNEW';
import LoginConfirm from '../components/LoginLayout/LoginConfirm';
import NotFound from './404';
import logo from '../images/logo.png';
import '../styles/adminStyles.css';

const { Header, Content, Footer } = Layout;

export default function Login() {
    return (
        <Layout>
            <Header className="header">
                <div className="header-content">
                    <div className="logo-and-text">
                        <img src={logo} className="logo" />
                        <div className="logo-text">ELECTO</div>
                    </div>
                </div>
            </Header>
            <Content style={{'width': '102%'}}>
                <Switch>
                    <Route path="/">
                        <div className="login-page">
                            <LoginLayoutNEW />
                        </div>
                    </Route>
                    <Route path="/login-confirm">
                        <LoginConfirm />
                    </Route>
                    <Route>
                        <NotFound />
                    </Route>
                </Switch>
            </Content>
        </Layout>
    );
}
