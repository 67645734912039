import {Button} from "antd";
import styles from "./QuestionLayout.module.css"
import {useEffect, useState} from "react";

const colors = {
    red: "#f5222d",
    orange: "#fa8c16",
    green: "#52c41a"
}

type OpinionSelectorProps = {
    onChange: (opinionIndex: 0 | 1 | 2) => any
    initialSelection: 0 | 1 | 2 | null
}

export default function OpinionSelector({onChange, initialSelection}: OpinionSelectorProps) {

    const [selection, setSelection] = useState<0 | 1 | 2 | null | undefined>();

    const onClick = (index: 0 | 1 | 2) => {
        setSelection(index);
        onChange(index);
    }
    useEffect(() => {
        setSelection(initialSelection);
    }, [initialSelection]);

    return (
        <div className={styles.opinionContainer}>
            <Button className={styles.opinionBtn}
                    style={{
                        color: selection === 0 ? colors.green : "white",
                        borderColor: colors.green,
                        backgroundColor: selection === 0 ? "transparent" : colors.green
                    }}
                    onClick={() => onClick(0)}>
                Pritariu
            </Button>
            <Button className={styles.opinionBtn}
                    style={{
                        color: selection === 1 ? colors.orange : "white",
                        borderColor: colors.orange,
                        backgroundColor: selection === 1 ? "transparent" : colors.orange
                    }}
                    onClick={() => onClick(1)}>
                Neturiu nuomonės
            </Button>
            <Button className={styles.opinionBtn}
                    style={{
                        color: selection === 2 ? colors.red : "white",
                        borderColor: colors.red,
                        backgroundColor: selection === 2 ? "transparent" : colors.red
                    }}
                    onClick={() => onClick(2)}>
                Nepritariu
            </Button>
        </div>
    )
}
