import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import InboxIcon from '@mui/icons-material/Inbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import Typography from '@mui/material/Typography';
import {CheckCircleTwoTone} from "@ant-design/icons";
import styles from "./PlayersList.module.css"
import {PlayerDoc} from "../../firebase/firestoreTypes";
import {sortPlayers, toPercentage} from "../../firebase/utils";

type PlayersListProps = {
    players: PlayerDoc[],
    showResults?: boolean,
    currentPlayer: string
}
export default function PlayersList({players, currentPlayer, showResults = false}: PlayersListProps) {

    return (
        <List>
            {sortPlayers(players).map((item) => {
                return (
                    <ListItem className={styles.listitem}
                    sx={{
                        margin: "10px 0",
                        border: "2px solid #2E2836",
                        borderRadius: "10px",
                    }}>
                
                    {item.partyName}
                    <div className={styles.playertag}>{item.userId === currentPlayer ? " (jūs)" : " "}</div>
                    <CheckCircleTwoTone twoToneColor={"#2E2836"}
                        style={{
                            padding: "0 4px",
                            fontSize: "1.2em",
                            opacity: item.moved ? 1 : 0
                    }}/>
                    {showResults ?
                        <span style={{float: "right"}}>
                            {toPercentage(item.score.totals.support)}
                        </span> : null
                    }
                </ListItem>
                )

            })}
        </List>
    )
}