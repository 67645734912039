import {Table} from "antd";
import {PlayerDoc} from "../../firebase/firestoreTypes";
import {sortPlayers, toPercentage} from "../../firebase/utils";

const getChangeColor = (change: number) => {
    if(change < 0) return "#f5222d";
    if(change > 0) return "#52c41a";
    if(change === 0) return "#8c8c8c";
}

const columns = [
    {
        title: "Partija",
        dataIndex: "partyName",
        key: "partyName"
    },
    {
        title: "Jaunimas",
        dataIndex: ["score", "jaunimas"],
        key: "jaunimas",
        render: (score: { support: number, change: number }) =>
            <div>
                {toPercentage(score.support)} {" "}
                <span style={{color: getChangeColor(score.change)}}>
                    {score.change >= 0 ? "+": ""}{toPercentage(score.change)}
                </span>
            </div>
    },
    {
        title: "Miestiečiai",
        dataIndex: ["score", "miestieciai"],
        key: "miestieciai",
        render: (score: { support: number, change: number }) =>
            <div>
                {toPercentage(score.support)} {" "}
                <span style={{color: getChangeColor(score.change)}}>
                    {score.change >= 0 ? "+": ""}{toPercentage(score.change)}
                </span>
            </div>
    },
    {
        title: "Regionai",
        dataIndex: ["score", "regionai"],
        key: "regionai",
        render: (score: { support: number, change: number }) =>
            <div>
                {toPercentage(score.support)} {" "}
                <span style={{color: getChangeColor(score.change)}}>
                    {score.change >= 0 ? "+": ""}{toPercentage(score.change)}
                </span>
            </div>
    },
    {
        title: "Senjorai",
        dataIndex: ["score", "senjorai"],
        key: "senjorai",
        render: (score: { support: number, change: number }) =>
            <div>
                {toPercentage(score.support)} {" "}
                <span style={{color: getChangeColor(score.change)}}>
                    {score.change >= 0 ? "+": ""}{toPercentage(score.change)}
                </span>
            </div>
    },
    {
        title: "Bendras palaikymas",
        dataIndex: ["score", "totals"],
        key: "total",
        render: (score: { support: number, change: number }) =>
            <div>
                {toPercentage(score.support)} {" "}
                <span style={{color: getChangeColor(score.change)}}>
                    {score.change >= 0 ? "+": ""}{toPercentage(score.change)}
                </span>
            </div>
    },
]

const data = [
    {
        key: "Partija 1",
        partyName: "Partija 1",
        jaunimas: {support: 0.4, change: 0.1},
        miestieciai: {support: 0.4, change: -0.1},
        regionai: {support: 0.6, change: 0.0},
        senjorai: {support: 0.4, change: 0.4},
        total: {support: 1.0, change: -0.5}
    }
]

type ScoreTableProps = {
    players: PlayerDoc[],
    currentPlayerId?: string
}

export default function ScoreTable({players, currentPlayerId}: ScoreTableProps) {
    return (
        <Table columns={columns} dataSource={sortPlayers(players)} pagination={false}/>
    )
}
