import Title from "antd/lib/typography/Title";
import OpinionSelector from "./OpinionSelector";
import {Col, Divider, Row, Space, Spin, Steps} from "antd";
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import {Button} from '@mui/material';
import MoneySlider from "./MoneySlider";
import styles from "./QuestionLayout.module.css"
import {ArrowLeftOutlined, ArrowRightOutlined} from "@ant-design/icons";
import {useEffect, useState} from "react";
import {PlayerDoc, QuestionDoc} from "../../firebase/firestoreTypes";
import {useFirestore, useFirestoreDocData} from "reactfire";
import {initialMoveState, questionCategories, sliders, SliderTypes, StepState} from "./Consts";
import {backendFinishMove} from "../../api/api";

type QuestionLayoutProps = {
    code: string
    currentPlayer?: PlayerDoc
    currentMove: number
}


export default function QuestionLayout({code, currentPlayer, currentMove}: QuestionLayoutProps) {

    // Max amount of money player can have during this move. Only changes on new step (question) change
    const [totalMoveMoney, setTotalMoveMoney] = useState(-1);
    // Total money player has minus all expenses in this move. Changes depending on choices.
    const [totalMoney, setTotalMoney] = useState(-1);
    // For each step we add 5k and track them whether they've been added already.
    //const [moneyToAdd, setMoneyToAdd] = useState([5000, 5000, 5000]);
    //# i believe adding money each step is kinda useless, and the right amount of money should be awarded from the start of the move
    const [moneyToAdd, setMoneyToAdd] = useState([0, 0, 0]);
    const [moveState, setMoveState] = useState<StepState[]>(structuredClone(initialMoveState));
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const gameCollection = useFirestore().collection(code)
    const questions = useFirestoreDocData<QuestionDoc>(gameCollection.doc("questions"));

    console.log(moveState);
    const onOpinionChanged = (index: 0 | 1 | 2) => {
        setMoveState(prevState => {
            let currentStep: StepState = {...prevState[currentQuestion], choice: index};
            let newState = [...prevState];
            newState[currentQuestion] = currentStep;
            return newState;
        });
    }

    const countMoney = (stepState: StepState) => {
        let sum = 0
        for (const slider of sliders) {
            sum += stepState[slider.name];
        }
        return sum;
    }

    const onMoneyChanged = (slider: string, value: number) => {
        if(value < 0) value = 0;//trying to remove negative budget for ads

        setMoveState(prevState => {
            const newState = structuredClone(prevState);
            newState[currentQuestion][slider as SliderTypes] = value;
            const moneySum = newState.reduce((previousValue, currentValue) =>
                previousValue + countMoney(currentValue), 0);
            const moneyLeft = totalMoveMoney - moneySum;
            // If not enough money, clip to max possible value
            if(moneyLeft < 0) {
                // Update value for later
                newState[currentQuestion][slider as SliderTypes] = value + moneyLeft;
                // Update new total money amount
                setTotalMoney(0);
                return newState;
            }
            // Update new total money amount
            setTotalMoney(totalMoveMoney - moneySum);
            return newState;
        });

        // If we have enough money, update
        // why is this code redundant?
        if (totalMoney - value >= 0) {

        }
    }
    // Initialize total money
    useEffect(() => {
        // -1 is uninitialized state
        if (totalMoney === -1 && currentPlayer) {
            setTotalMoveMoney(currentPlayer.money);
            setTotalMoney(currentPlayer.money);
            setMoveState(structuredClone(initialMoveState));
        }
    }, [currentPlayer]);

    useEffect(()=>{
        setMoveState(structuredClone(initialMoveState));
    },[currentMove])

    const finishMove = () => {
        if (currentPlayer) {
            backendFinishMove(moveState, code, currentMove, currentPlayer.partyName);
        }
    }
    const onNextClicked = () => {
        if (currentQuestion === questionCategories.length - 1) {
            finishMove();
            return;
        }
        // Add 5k if it's first time switching
        setTotalMoney(prevState => prevState + moneyToAdd[currentQuestion]);
        setTotalMoveMoney(prevState => prevState + moneyToAdd[currentQuestion]);
        setMoneyToAdd(prevState => {
            const t = [...prevState];
            t[currentQuestion] = 0;
            return t;
        });
        setCurrentQuestion(currentQuestion => currentQuestion + 1);
    }
    const onBackClicked = () => {
        setCurrentQuestion(currentQuestion => currentQuestion - 1 >= 0 ? currentQuestion - 1 : 0);
    }

    if (!currentPlayer || questions.status === "loading") {
        return (
            <Space align={"center"} size="middle">
                <Spin size="large"/>
            </Space>
        )
    }

    return (
        <div>
            <div className={styles.questionContent}>
                <div className={styles.container}>
                    <Title level={2}>{questionCategories[currentQuestion].category}</Title>
                    <div style={{fontSize: "1.75em"}}>{questions.status === "success" ? questions.data[questionCategories[currentQuestion].key][currentMove - 1].question : ""}</div>
                    <OpinionSelector onChange={onOpinionChanged} initialSelection={moveState[currentQuestion].choice}/>
                </div>
                <div className={styles.questionDivider}></div>
                <div className={styles.container}>
                <Title level={2}>Reklamos kanalai</Title>
                    <Title style={{width: "100%", textAlign: "right"}}
                        level={3}>{`Biudžetas: ${totalMoney} €`}</Title>
                    <Row>
                        {sliders.map(slider =>
                            <Col key={slider.name} span={24}>
                                {/* Note: max value is hardcoded to avoid changing sliders */}
                                {/* I would hardcode the slider value to like 5000 because you get money on each
                                turn and you can have left over money from previous turns*/}
                                <MoneySlider name={slider.name} label={slider.label} max={currentPlayer?.money}
                                            value={moveState[currentQuestion][slider.name]}
                                            onChange={onMoneyChanged}/>
                            </Col>
                        )}
                    </Row>
                </div>
            </div>
            
            <div className={styles.navBtnContainer}>
                <Button className={styles.navBtn}
                        onClick={onBackClicked}
                        disabled={currentQuestion === 0}
                        sx={{
                            border: currentQuestion === 0 ? '2px solid rgba(0,0,0,0.2)' : '2px solid #2E2836',
                            color: "#ffffff",
                            background: currentQuestion === 0 ? "rgba(0,0,0,0)" : "#2E2836",
                            '&:hover': {
                                color: "#2E2836"
                            }
                          }}>
                    <ArrowLeftOutlined/> Atgal
                </Button>
                <Button className={styles.navBtn}
                        onClick={onNextClicked}
                        disabled={moveState[currentQuestion].choice === null}
                        sx={{
                            border: moveState[currentQuestion].choice === null ? '2px solid rgba(0,0,0,0.2)' : '2px solid #000000',
                            color: "#ffffff",
                            background: moveState[currentQuestion].choice === null ? "rgba(0,0,0,0)" : "#000000",
                            '&:hover': {
                                color: "#000000"
                            }
                          }}>
                    {currentQuestion === questionCategories.length - 1 ? "Baigti" : (<>Toliau <ArrowRightOutlined/></>)}
                </Button>
            </div>
            <Stepper activeStep={currentQuestion} alternativeLabel sx={{
                paddingTop: "12px",
            }}>
                {questionCategories.map((category, i) => (
                    <Step key={i} sx={{
                        '& .MuiStepLabel-root .Mui-completed': {
                            color: '#2E2836', // circle color (COMPLETED)
                        },
                        '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel': {
                            color: '#2E2836', // Just text label (COMPLETED)
                        },
                        '& .MuiStepLabel-root .Mui-active': {
                            color: '#2E2836', // circle color (ACTIVE)
                        },
                        '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel': {
                            color: '#2E2836', // Just text label (ACTIVE)
                        },
                        '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
                            fill: '#ffffff', // circle's number (ACTIVE)
                        },
                    }}>
                        <StepLabel className = {styles.stepper} >{category.category}</StepLabel>
                    </Step>
                ))}
            </Stepper>
        </div>
    )
}
