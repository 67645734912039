import React, {useEffect, useState} from "react";
import styles from "./ChatWidget.module.css"
import {ChatDoc, ChatMessage, PlayerDoc} from "../../firebase/firestoreTypes";
import InnerChat from "./InnerChat";
import {useFirestore, useFirestoreDocData} from "reactfire";
import {sendChatMessage} from "../../firebase/firestore-api";
import {CloseOutlined, CommentOutlined} from "@ant-design/icons";
import {Avatar, Badge} from "antd";

type ChatWidgetProps = {
    code: string
    currentPlayer?: PlayerDoc
}

export default function ChatWidget({code, currentPlayer}: ChatWidgetProps) {
    const firestore = useFirestore();
    const chatRef = firestore.collection(code).doc("chat");
    const chat = useFirestoreDocData<ChatDoc>(chatRef);
    // used for detecting new messages
    const [messageCount, setMessageCount] = useState(0);
    const [newMessage, setNewMessage] = useState(false);
    const [maximized, setMaximized] = useState(false);
    const sendMessage = async (message: string) => {
        const chatMsg = {
            text: message,
            authorId: currentPlayer?.userId ?? "admin",
            name: currentPlayer?.partyName ?? "Administratorius",
            parsedDate: new Date().toUTCString()
        } as ChatMessage;
        await sendChatMessage(firestore, code, chatMsg);
    };

    useEffect(() => {
        if(chat.data?.global.length > messageCount) {
            setMessageCount(chat.data?.global.length);
            setNewMessage(true);
        }
        // ignore if you are looking at the messages
        if(maximized){
            setNewMessage(false);
        }
    }, [chat, maximized])

    return maximized ? (
            <div className={styles.wrapper}>
                <div className={styles.chatHeader}>
                    <CloseOutlined className={styles.closeButton} onClick={() => setMaximized(false)}/>
                    <div className={styles.chatTitle}>Žinutės</div>
                    <p className={styles.chatDesc}>Čia galite bendrauti su visais žaidėjais</p>
                </div>
                <div className={styles.content}>
                    <InnerChat messages={chat.data?.global ?? []} currentPlayer={currentPlayer} onSend={sendMessage}/>
                </div>
            </div>
        )
        :
        (
            <div onClick={() => setMaximized(true)} className={styles.minimizedWrapper}>
                <Badge count={newMessage ? 1 : 0} dot offset={[-8, 8]}  className={styles.badge}>
                    <Avatar className={styles.minimizedCircle} size={64} icon={<CommentOutlined/>}/>
                </Badge>
            </div>
        )
}