// @ts-nocheck
import React, {useEffect, useState} from 'react';
import {Choropleth} from '@nivo/geo'
import {ResponsiveWrapper} from '@nivo/core'
import ltGeo from './lt.json'
import demographics from './demography.json'
import * as geo from 'd3-geo'
import {ExtendedFeatureCollection} from 'd3-geo'
import {PlayerDoc} from "../../firebase/firestoreTypes";
import {ObjectEntries} from "../../firebase/utils";

const initialData: MapData = [
    {id: "AS", value: 0, jaunimas: 0, miestieciai: 0, regionai: 0, senjorai: 0},
    {id: "KS", value: 0, jaunimas: 0, miestieciai: 0, regionai: 0, senjorai: 0},
    {id: "MA", value: 0, jaunimas: 0, miestieciai: 0, regionai: 0, senjorai: 0},
    {id: "PA", value: 0, jaunimas: 0, miestieciai: 0, regionai: 0, senjorai: 0},
    {id: "SH", value: 0, jaunimas: 0, miestieciai: 0, regionai: 0, senjorai: 0},
    {id: "TG", value: 0, jaunimas: 0, miestieciai: 0, regionai: 0, senjorai: 0},
    {id: "UN", value: 0, jaunimas: 0, miestieciai: 0, regionai: 0, senjorai: 0},
    {id: "VI", value: 0, jaunimas: 0, miestieciai: 0, regionai: 0, senjorai: 0},
    {id: "KP", value: 0, jaunimas: 0, miestieciai: 0, regionai: 0, senjorai: 0},
    {id: "TL", value: 0, jaunimas: 0, miestieciai: 0, regionai: 0, senjorai: 0}
]


type ChoroplethMapProps = {
    players: PlayerDoc[],
    currentPlayer?: PlayerDoc,
    isAdmin: boolean
}

type MapData = {
    id: string,
    value: number
    jaunimas: number
    miestieciai: number
    regionai: number
    senjorai: number
}[]

// Janky
type AdminMapData = {
    id: string | any,
    value: number,
    [partyName: string]: number
}[]

export default function ChoroplethMap({players, currentPlayer, isAdmin}: ChoroplethMapProps) {
    const mercator = geo.geoMercator();
    const [loading, setLoading] = useState(true);
    const [currentPlayerStats, setCurrentPlayerStats] = useState<MapData>(initialData);
    const [playerStats, setPlayerStats] = useState<AdminMapData>([]);
    const [maxVal, setMaxVal] = useState(0);

    useEffect(() => {
        if (currentPlayer) {
            setLoading(true);
            const newStats = currentPlayerStats.map(municip => {
                // Not sure if needed, but just in case.
                const newMunicip = {...municip};
                for (const [group, scores] of ObjectEntries(currentPlayer.score)) {
                    if (group !== "totals") {
                        // @ts-ignore
                        const people = Math.round(scores.support * demographics[group][newMunicip.id]);
                        newMunicip[group] = people;
                    }
                }
                newMunicip.value = demographics.totals[newMunicip.id] * currentPlayer.score.totals.support;
                return newMunicip;
            });
            setCurrentPlayerStats(newStats);
            setLoading(false);
        }
    }, [currentPlayer]);

    useEffect(() => {
            const adminMapData: AdminMapData = []
            setLoading(true);
            for (const [municip, popul] of ObjectEntries(demographics.totals)) {
                let obj = {id: municip, value: 0}
                for (const player of players) {
                    const p = player.score.totals.support * popul;
                    obj[player.partyName] = p;
                    obj.value += p;
                    // Also find max value for map color upper bound
                    if (p > maxVal) {
                        setMaxVal(p);
                    }
                }
                adminMapData.push(obj);
            }
            setPlayerStats(adminMapData);
            setLoading(false);
    }, [players])
    console.log(playerStats);

    return (
        <div style={{height: 400}}>
            <ResponsiveWrapper>
                {({width, height}) => {
                    mercator.fitSize([width, height], ltGeo as ExtendedFeatureCollection);
                    let [tx, ty] = mercator.translate()
                    return (
                        <Choropleth
                            width={width}
                            height={height}
                            data={isAdmin ? playerStats : currentPlayerStats}
                            features={ltGeo.features}
                            margin={{top: 0, right: 0, bottom: 0, left: 0}}
                            colors="RdYlGn"
                            domain={[0, maxVal]}
                            unknownColor="#666666"
                            label="properties.name"
                            valueFormat=".2s"
                            projectionType={"mercator"}
                            projectionTranslation={[tx / width, ty / height]}
                            projectionRotation={[0, 0, 0]}
                            projectionScale={mercator.scale()}
                            enableGraticule={false}
                            borderWidth={0.5}
                            borderColor="#888888"
                            legends={[
                                {
                                    anchor: 'left',
                                    direction: 'column',
                                    justify: true,
                                    translateX: 30,
                                    translateY: -20,
                                    itemsSpacing: 0,
                                    itemWidth: 94,
                                    itemHeight: 18,
                                    itemDirection: 'left-to-right',
                                    itemTextColor: '#444444',
                                    itemOpacity: 0.85,
                                    symbolSize: 18,
                                    effects: [
                                        {
                                            on: 'hover',
                                            style: {
                                                itemTextColor: '#000000',
                                                itemOpacity: 1
                                            }
                                        }
                                    ]
                                }
                            ]}
                            tooltip={(e) => (
                                <div style={{backgroundColor: "white", padding: "0.5rem", textAlign: "left", border: "black solid 1px"}}>
                                    {!isAdmin ?
                                    <div>
                                            <div style={{textAlign: "center"}}>
                                                <b>
                                                    {e.feature.properties.name} apskr.
                                                </b>
                                            </div>
                                            <div>Jaunimas: {e.feature.data.jaunimas}</div>
                                            <div>Miestiečiai: {e.feature.data.miestieciai}</div>
                                            <div>Regionai: {e.feature.data.regionai}</div>
                                            <div>Senjorai: {e.feature.data.senjorai}</div>
                                    </div>
                                        :
                                        <div>
                                            <div style={{textAlign: "center"}}>
                                                <b>
                                                    {e.feature.properties.name} apskr.
                                                </b>
                                            </div>
                                            {ObjectEntries(e.feature.data).map(([key, val], i) => {
                                                if(key !== 'id' && key !== 'value'){
                                                    return <div key={i}>{key}: {Math.round(val)}</div>
                                                }
                                                return null
                                            })}
                                        </div>

                                    }
                                </div>
                            )}
                        />);
                }}
            </ResponsiveWrapper>
        </div>
    );
}
