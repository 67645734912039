import {Button, Form, Input, Modal, Slider} from "antd";
import React, {useState} from "react";
import {ValidateStatus} from "antd/lib/form/FormItem";


type ModalFormProps = {
    visible: boolean;
    onCreate: (values: any, setError: (field: string, errorMsg: string) => void) => void;
    onCancel: () => void;
}

type ValidationState = {
    validateStatus?: ValidateStatus;
    errorMsg?: string | null;
}


export const CreateNewGameForm: React.FC<ModalFormProps> = ({visible, onCreate, onCancel}) => {

    const [questionSetSelected, setQuestionSetSelected] = useState<1|2>(1); // Default selected question set

    const [validation, setValidation] = useState<{
        title: ValidationState,
        totalMoves: ValidationState
    }>({
        title: {},
        totalMoves: {}
    });
    const [loading, setLoading] = useState(false);

    const setError = (field: string, errorMsg: string) => {
        setLoading(false);
        setValidation({...validation, [field]: {validateStatus: "error", errorMsg: errorMsg}});
    }

    const [form] = Form.useForm();
    return (
        <Modal
            visible={visible}
            title="Sukurti naują žaidimą"
            okText="Sukurti"
            cancelText="Atšaukti"
            onCancel={onCancel}
            confirmLoading={loading}
            onOk={() => {
                setLoading(true);
                form
                    .validateFields()
                    .then(values => {
                        // form.resetFields();
                        values.questionSetSelected = questionSetSelected;
                        onCreate(values, setError);
                    })
                    .catch(info => {
                        setLoading(false);
                        console.log('Validate Failed:', info);
                    });
            }}
        >
            <Form
                form={form}
                layout="vertical"
                name="create_form"
                initialValues={{totalMoves: 1}}
            >
                <Form.Item
                    name="title"
                    label="Pavadinimas"
                    validateStatus={validation.title.validateStatus}
                    help={validation.title.errorMsg}
                    rules={[{required: true, message: 'Įveskite žaidimo pavadinimą'}]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    name="totalMoves"
                    label="Ėjimų skaičius"
                    validateStatus={validation.totalMoves.validateStatus}
                    help={validation.totalMoves.errorMsg}>
                    <Slider min={1} max={10} marks={{1: "1", 10: "10"}} tooltipPlacement="bottom"/>
                </Form.Item>    
            </Form>
            <div style={{"justifyContent":"center", display:'flex',"gap":"10px"}}>
                <Button
                    type={questionSetSelected === 1 ? 'primary' : 'default'}
                    onClick={() => setQuestionSetSelected(1)}
                >
                    Pilietiškumo mokykla
                </Button>
                <Button
                    type={questionSetSelected === 2 ? 'primary' : 'default'}
                    onClick={() => setQuestionSetSelected(2)}
                >
                    Europietiškumo mokykla
                </Button>
            </div>
        </Modal>
    )
};

export const JoinGameForm: React.FC<ModalFormProps> = ({visible, onCreate, onCancel}) => {

    const [validation, setValidation] = useState<{
        code: ValidationState,
        partyName: ValidationState
    }>({
        code: {},
        partyName: {}
    });
    const [loading, setLoading] = useState(false);

    const setError = (field: string, errorMsg: string) => {
        setLoading(false);
        setValidation({...validation, [field]: {validateStatus: "error", errorMsg: errorMsg}});
    }

    const [form] = Form.useForm();
    return (
        <Modal
            visible={visible}
            title="Prisijungti prie žaidimo"
            okText="Prisijungti"
            cancelText="Atšaukti"
            onCancel={onCancel}
            confirmLoading={loading}
            onOk={() => {
                setLoading(true);
                form
                    .validateFields()
                    .then(values => {
                        // form.resetFields();
                        onCreate(values, setError);
                    })
                    .catch(info => {
                        console.log('Validate Failed:', info);
                    });
            }}
        >
            <Form
                form={form}
                layout="vertical"
                name="join_form"
                initialValues={{modifier: 'public'}}
            >
                <Form.Item
                    name="code"
                    label="Kodas"
                    validateStatus={validation.code.validateStatus}
                    help={validation.code.errorMsg}
                    rules={[{required: true, message: 'Įveskite žaidimo kodą'},
                        {pattern: /\d+/, message: "Neteisingas kodas"}]}
                >
                    <Input type="number" maxLength={10}/>
                </Form.Item>
                <Form.Item
                    name="partyName"
                    label="Partijos pavadinimas"
                    validateStatus={validation.partyName.validateStatus}
                    help={validation.partyName.errorMsg}
                    rules={[{required: true, message: 'Įveskite partijos pavadinimą'}]}
                >
                    <Input maxLength={40}/>
                </Form.Item>
            </Form>
        </Modal>
    )
};

export const SubmitMayorForm: React.FC<ModalFormProps & {duplicateWarning: boolean}> = ({visible, onCreate, onCancel, duplicateWarning}) => {

    const [form] = Form.useForm();
    return (
        <Modal
            visible={visible}
            title="Mero nominacija"
            okText="Nominuoti"
            cancelText="Atšaukti"
            onCancel={onCancel}
            onOk={() => {
                form
                    .validateFields()
                    .then(values => {
                        // form.resetFields();
                        onCreate(values, () => null);
                    })
                    .catch(info => {
                        console.log('Validate Failed:', info);
                    });
            }}>
            {duplicateWarning && <div style={{color: "red"}}>Dėmėsio! Jūs jau esate nominavę merą.</div>}
            <Form
                form={form}
                layout="vertical"
                name="join_form"
                initialValues={{modifier: 'public'}}>
                <Form.Item
                    name="nomination"
                    label="Vardas ir pavardė"
                    rules={[{required: true, message: 'Įveskite vardą'}]}>
                    <Input maxLength={50}/>
                </Form.Item>
            </Form>
        </Modal>)

}
