import styles from "./ChatWidget.module.css";
import {ChatContainer, Message, MessageInput, MessageList, MessageModel} from "@chatscope/chat-ui-kit-react";
import React, {useRef} from "react";
import {ChatMessage, PlayerDoc} from "../../firebase/firestoreTypes";

type InnerChatProps = {
    messages: ChatMessage[]
    currentPlayer?: PlayerDoc
    onSend: (message: string) => void
}

export default function InnerChat({messages, currentPlayer, onSend}: InnerChatProps) {

    const inputRef = useRef<HTMLInputElement>();


    return (
        <ChatContainer className={styles.chat}>
        <MessageList scrollBehavior="smooth">
            {messages.map((m, i) => (
                <Message key={i} model={{
                    message: m.text,
                    direction: m.authorId === (currentPlayer?.userId ?? "admin") ? "outgoing" : "incoming",
                    position: "single"
                }}>
                    <Message.Header className={m.authorId === "admin" ? styles.admin : undefined} sender={m.name}>

                    </Message.Header>
                </Message>
            ))}
        </MessageList>
        <MessageInput placeholder="Rašykite čia..."
                      onSend={onSend}
                      ref={inputRef}
                      attachButton={false}
                      className={styles.chatInput}/>
    </ChatContainer>
    )
}
