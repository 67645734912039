import styles from './Home.module.css'
import {Button, Card, Layout} from 'antd';
import Title from "antd/lib/typography/Title";
import GameHeader from "../components/GameHeader";
import {useFirestore, useFirestoreCollectionData, useFirestoreDocData, useUser} from "reactfire";
import {ParamsDoc, PlayerDoc} from "../firebase/firestoreTypes";
import {firestoreStartGame} from "../firebase/firestore-api";
import LobbyNamesList from "../components/LobbyNamesList";

type LobbyProps = {
    code: string
}

export default function Lobby({code}: LobbyProps) {

    const {Content, Footer} = Layout;
    const firestore = useFirestore();
    const startGame = () => firestoreStartGame(firestore, code);
    const gameCollection = useFirestore().collection(code);
    const playerDocs = useFirestoreCollectionData<PlayerDoc>(
        gameCollection.where("isPlayer", "==", true)
    );
    const gameParams = useFirestoreDocData<ParamsDoc>(gameCollection.doc("params"));
    const user = useUser();

    return (
        <Layout className={styles.layout}>
            <GameHeader isLobby={true} title={gameParams.data?.name}/>
            <Content style={{padding: '50px'}}>
                <div className={styles.content}>
                    <Card>
                        <Title level={3}>Kodas</Title>
                        <p style={{fontSize: "4.5em", fontWeight: "bold", margin: 0}}>{code}</p>
                    </Card>
                    <LobbyNamesList names={playerDocs.data?.map(player => player.partyName) ?? []} seed={code}/>
                    {/*{playerDocs.data?.map(player => <LobbyNamesList>{player.partyName}</LobbyNamesList>)}*/}
                    {
                        user.data?.uid === gameParams.data?.createdBy ?
                            <Button type="primary" disabled={playerDocs.data?.length < 1} onClick={startGame}
                                    style={{margin: 16, marginTop: "4em"}}>
                                Pradėti žaidimą
                            </Button>
                            :
                            null
                    }
                </div>
            </Content>
            <Footer style={{textAlign: 'center'}}>© Electo 2024, Jaunųjų konservatorių lygos (JKL) autorinė nuosavybė.</Footer>
        </Layout>
    )
}
