import {Card, Col, Row, Statistic} from "antd";
import {PlayerDoc} from "../../firebase/firestoreTypes";
import {getPlace, maxGroup, sortPlayers, sortPlayersByMoney, toPercentage} from "../../firebase/utils";

type StatsProps = {
    players: PlayerDoc[]
    currentPlayer?: PlayerDoc
    isAdmin: boolean
}

export default function Stats({currentPlayer, players, isAdmin}: StatsProps) {
    return (
        <Row gutter={8}>
            <Col xs={24} sm={24} md={8} lg={8}>
                <Card>
                    {isAdmin ?
                        <Statistic title="Didžiausi finansiniai ištekliai"
                                   loading={!players}
                                   value={sortPlayersByMoney(players ?? [])[0].partyName}/> :
                        <Statistic title="Bendras palaikymas"
                                   loading={!currentPlayer}
                                   value={toPercentage(currentPlayer?.score.totals.support ?? 0)}/>
                    }
                </Card>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8}>
                <Card>
                    {isAdmin ?
                        <Statistic title="Partijų skaičius"
                                   loading={!players}
                                   value={players?.length}/> :
                        <Statistic title="Pozicija"
                                   loading={!currentPlayer}
                                   value={currentPlayer && `${getPlace(players, currentPlayer)} Vieta`}/>
                    }
                </Card>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8}>
                <Card>
                    {isAdmin ?
                        <Statistic title="Pirmaujanti partija"
                                   loading={!players}
                                   value={sortPlayers(players ?? [])[0]?.partyName}/> :
                        <Statistic title="Labiausiai palaiko"
                                   loading={!currentPlayer}
                                   value={currentPlayer && maxGroup(currentPlayer)}/>
                    }
                </Card>
            </Col>
        </Row>
    )
}