import {Button, Tabs} from "antd";
import Stats from "./Stats";
import Title from "antd/lib/typography/Title";
import ScoreTable from "./ScoreTable";
import ChoroplethMap from "./ChoroplethMap";
import Chart from "./Chart";
import {PlayerDoc} from "../../firebase/firestoreTypes";
import NewsHeadings from "./NewsHeadings";
import {firestoreNextMove} from "../../firebase/firestore-api";
import {useFirestore} from "reactfire";

const {TabPane} = Tabs;

type ResultsLayoutProps = {
    players: PlayerDoc[]
    currentPlayer?: PlayerDoc
    isAdmin: boolean
    code: string
}

export default function ResultsLayout({players, currentPlayer, isAdmin, code}: ResultsLayoutProps) {

    const firestore = useFirestore();

    return (
        <div style={{height: "100%"}}>
            <Title level={2}>Rezultatai</Title>
            <Stats currentPlayer={currentPlayer} players={players} isAdmin={isAdmin}/>
            <Tabs style={{marginTop: "2em"}}>
                <TabPane style={{flex: 1}} tab={"Grafikas"} key={0}>
                    <Chart players={players}/>
                </TabPane>
                <TabPane style={{flex: 1}} tab={"Lentelė"} key={1}>
                    <ScoreTable players={players} currentPlayerId={currentPlayer?.userId}/>
                </TabPane>
                <TabPane style={{flex: 1}} tab={"Žemėlapis"} key={2}>
                    <ChoroplethMap players={players} currentPlayer={currentPlayer} isAdmin={isAdmin}/>
                </TabPane>
            </Tabs>
            <NewsHeadings players={players}/>
            {isAdmin ?
                <Button type="primary" block={true} size="large" style={{marginTop: "1rem"}}
                onClick={() => firestoreNextMove(firestore, code)}>
                    Pradėti kitą ėjimą
                </Button>
            :
            null
            }
        </div>
    )
}
