import {PlayerDoc} from "../../firebase/firestoreTypes";
import Title from "antd/lib/typography/Title";
import {Progress} from "antd";
import {useEffect, useState} from "react";

export default function WaitingForPlayers({players}: { players: PlayerDoc[] }) {

    const [finishedPlayers, setFinishedPlayers] = useState<number>(0);

    useEffect(() => {
        let f = 0
        for (const player of players) {
            if(player.moved) f++;
        }
        setFinishedPlayers(f);
    }, [players])

    return (
        <div>
            <Title>Laukiama kitų žaidėjų</Title>
            <Progress type="circle"
                      size="default"
                      percent={(finishedPlayers / players.length) * 100}
                      format={_ => `${finishedPlayers}/${players.length}`} />
        </div>
    )
}