import React, { useState } from "react";
import {
  Container,
  Box,
  TextField,
  Button,
  Divider,
  CircularProgress,
  Checkbox,
  FormControlLabel,
  Link,
} from "@mui/material";
import GoogleIcon from "@mui/icons-material/Google";
import AppleIcon from "@mui/icons-material/Apple";
import { useAuth, useFirestore } from "reactfire";
import firebase from "firebase";
import { createUserDocument } from "../../firebase/firestore-api";
import '../../styles/LoginPage.css';
import backgroundImage from '../../images/ep-inside.jpg';

const debugging = true;

export default function LoginLayoutNEW() {
  const [loading, setLoading] = useState({ email: false, google: false, guest: false });
  const auth = useAuth();
  const firestore = useFirestore();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);

  const googleLogin = async () => {
    setLoading((prev) => ({ ...prev, google: true }));
    try {
      const GoogleAuthProvider = new firebase.auth.GoogleAuthProvider();
      debugging && console.log('retrieved provider');
      const credential = await auth.signInWithPopup(GoogleAuthProvider);
      debugging && console.log('retrieved credential');
      if (credential.additionalUserInfo?.isNewUser) {
        await createUserDocument(firestore, credential.user.uid).then(() => {
          setLoading((prev) => ({ ...prev, google: false }));
        });
        debugging && console.log('Created account');
      }
    } catch (e) {
      console.error(e);
      alert('Nenumatyta klaida naudojant Google prisijungimą.');
    } finally {
      setLoading((prev) => ({ ...prev, google: false }));
    }
  };

  const emailLogin = async (e) => {
    e.preventDefault();
    console.error("email login doesnt work, fix req'd");
    //alert('Šiuo metu prisijungimo su el. paštu neveikia, prašome naudoti Google prisijungimą.');
  };

  const guestContinue = async () => {
    console.error("guest login doesnt work, fix req'd");
    //alert('Atsiprašome, šiuo metu svečio prisijungimas neveikia. Prašome naudoti Google prisijungimą.')
  };

  return (
    <>
        <Container maxWidth={false} sx={{ display: 'flex', minHeight: '100vh', alignItems: 'center', justifyContent: 'center', padding: 0 }}>
            <Box
                sx={{
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                width: '100%',
                }}
            >

                <Box
                sx={{
                    flex: 0.3,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    padding: 8,
                }}
                >

                    <h1>Sveikas!</h1>
                    <h3>Prisijunk prie ELECTO kad tęsti.</h3>

                    <Box component="form" onSubmit={emailLogin} noValidate className="loginFormBox">

                        <Button 
                            fullWidth 
                            variant="outlined" 
                            startIcon={<GoogleIcon />} 
                            onClick={googleLogin} 
                            disabled={loading.google} 
                            className="googleLoginButton"
                            >{loading.google ? <CircularProgress size={24} /> : "Prisijungti su Google"}
                        </Button>

                        <Divider sx={{ my: 2 }}>arba</Divider>

                        <TextField 
                            margin="normal" 
                            required 
                            fullWidth 
                            id="email" 
                            label="El. paštas" 
                            name="email" 
                            autoComplete="email" 
                            autoFocus value={email} 
                            onChange={(e) => setEmail(e.target.value)}
                        />

                        <TextField 
                            margin="normal" 
                            required fullWidth 
                            name="password" 
                            label="Slaptažodis" 
                            type="password" 
                            id="password" 
                            autoComplete="current-password" 
                            value={password} 
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        
                        <FormControlLabel control={<Checkbox value="remember" color="primary" checked={rememberMe} onChange={(e) => setRememberMe(e.target.checked)} />} 
                            label="Prisiminti mane"
                        />
                        
                        <Link href="#" variant="body2" sx={{ display: 'block', textAlign: 'right', mb: 2 }}>
                            Pamiršai slaptažodį?
                        </Link>

                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            disabled={loading.email}
                        >
                            {loading.email ? <CircularProgress size={24} /> : "Prisijungti"}
                        </Button>

                        <Button
                            fullWidth
                            variant="outlined"
                            sx={{ mt: 1 }}
                            onClick={guestContinue}
                            disabled={loading.guest}
                        >
                            {loading.guest ? <CircularProgress size={24} /> : "Tęsti kaip svečias"}
                        </Button>

                    </Box>
                </Box>
                <Box
                    sx={{
                        display: { xs: 'none', md: 'block' },
                        backgroundImage: `url(${backgroundImage})`,
                        height: '100vh',  // Ensures the image takes full height of the screen
                        width: '50vw'   
                    }}
                    className='sideImage'
                />
            </Box>
        </Container>
    </>
  );
}
