import {Card} from "antd";
import styles from "./IconCardButton.module.css"
import React from "react";

// TODO: What's the type for icon?
type IconCardButtonProps = {
    icon: any,
    title: string,
    description: string,
    onClick?: React.MouseEventHandler<HTMLElement>
}
/**
 * Card with hover effect consisting of an icon, a title and description
 * */
export default function IconCardButton({icon, title, description, onClick}: IconCardButtonProps) {
    const Icon = icon
    return (
        <Card hoverable className={styles.card} onClick={onClick}>
            <Icon className={styles.icon}/>
            <p className={styles.title}>{title}</p>
            <p className={styles.description}>{description}</p>
        </Card>
    )
}