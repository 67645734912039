import {StepState} from "../components/QuestionLayout/Consts";

export const backendFinishMove = async (moveState: StepState[], gameCode: string, move: number, partyName: string) => {
    const body = {
        results: [...moveState],
        gameCode: gameCode,
        move: move,
        partyName: partyName
    };

    try {
        const resp = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/finishMove`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(body),
            credentials: 'include' // Include credentials (cookies) in the request
        });

        if (resp.ok) {
            // If everything is ok, return 0 or handle success as needed
            return 0;
        } else {
            throw Error(resp.status.toString());
        }
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
};

export const backendInterruptMove = async (gameCode: string) => {
    const body = {
        gameCode: gameCode
    };

    try {
        const resp = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/interruptMove`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(body),
            credentials: 'include' // Include credentials (cookies) in the request
        });

        if (resp.ok) {
            // If everything is ok, return 0 or handle success as needed
            return 0;
        } else {
            throw Error(resp.status.toString());
        }
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
};