import {Slider} from '@mui/material';
import styles from "./QuestionLayout.module.css"
import {useEffect, useState} from "react";

type MoneySliderProps = {
    name: string,
    label: string,
    max: number,
    value: number,
    onChange?: (name: string, value: number) => any
}

export default function MoneySlider({name, label, max, value, onChange}: MoneySliderProps) {

    // const [value, setValue] = useState(initialValue);

    const onSliderChanged = (event: Event, value: number) => {
        if(onChange){
            onChange(name, value > 0 ? value : 0);//trying to eliminate negative budget for ads
        }
        // setValue(value);
    }
    // useEffect(() => {
    //     setValue(initialValue);
    // }, [initialValue]);

    return (
        <div className={styles.sliderContainer}>
            <Slider className={styles.slider} max={max} value={value} step={10} onChange={onSliderChanged} sx={
                {
                width: "95%",
                height: 20,
                color: "#2E2836",
                borderRadius: '2px',
                padding: '0px',
                '& .MuiSlider-thumb':{
                    borderRadius: '2px',
                    color: "#ffffff",
                    border: "2px solid #000000",
                    borderColor: "#2E2836",
                    width: 20,
                    height: 20,
                    '&:hover': {
                        boxShadow: '0 0 0 0px rgba(58, 133, 137, 0.16)',
                    }
                },
                '& .MuiSlider-rail': {
                    color: "#2E2836",
                    opacity: 0.1
                },
                }
                
            }/>
            <div className={styles.sliderTextContainer}>
                <div className={styles.sliderLabel}>{`${label}:`}</div>
                <div className={styles.sliderIndicator} >{`${value} €`}</div>
            </div>
        </div>
    )
}