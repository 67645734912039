import firebase from "firebase/app";
import {ChatMessage, GameRoutes, ParamsDoc, PlayerDoc, QuestionDoc, RouterDoc, UserDoc} from "./firestoreTypes";
import {getRandomNumberString, getRandomSubarray} from "./utils";

const isDebug = process.env.NODE_ENV !== "production"

const generateGameCode = async (firestore: firebase.firestore.Firestore) => {
    isDebug && console.log('generateGameCode');
    let gameStatsDoc = await firestore.collection("globals").doc("stats").get();
    if (gameStatsDoc.exists) {
        let gameStats = gameStatsDoc.data() as { gameCount: number };
        let number = gameStats.gameCount;
        await firestore.collection("globals").doc("stats")
            .update({gameCount: firebase.firestore.FieldValue.increment(1)});
        return getRandomNumberString(4 - number.toString().length) + number.toString();
    } else {
        throw Error("gameStats doc does not exist.");
    }
}

const addGameRefToUser = (firestore: firebase.firestore.Firestore,
                          batch: firebase.firestore.WriteBatch,
                          ref: firebase.firestore.DocumentReference,
                          uid: string) => {
    isDebug && console.log('addGameRefToUser', ref, uid);
    return batch.update(firestore.collection("users").doc(uid),
        {games: firebase.firestore.FieldValue.arrayUnion({ref: ref})});
};
/**
 * Get random questions for the game
 * */
const getQuestions = async (firestore: firebase.firestore.Firestore, totalMoves: number, questionSetSelected: 1 | 2) => {
    isDebug && console.log('getQuestions');
    const questionsDoc = await firestore.collection("globals").doc(questionSetSelected == 1 ? "questions" : "questions2").get();
    const allQuestions = questionsDoc.data() as QuestionDoc;
    const sampledQuestions = Object.fromEntries(
        Object.entries(allQuestions)
            .map(([category, questions]) => [category, getRandomSubarray(questions, totalMoves)])
    );
    return sampledQuestions as QuestionDoc;
}

/**
 * Creates a new game in database from given parameters.
 * */
export const createNewGame = async (firestore: firebase.firestore.Firestore,
                                    name: string, totalMoves: number, uid: string, questionSetSelected: 1 | 2) => {
    isDebug && console.log('createNewGame');
    const code = await generateGameCode(firestore);
    const batch = firestore.batch()
    const params = {
        // allTotals: [],
        code: code,
        createdBy: uid,
        dateCreated: firebase.firestore.FieldValue.serverTimestamp(),
        move: 0,
        totalMoves: totalMoves,
        name: name,
        nominations: []
    } as ParamsDoc;

    const gameRef = firestore.collection(code);
    batch.set(gameRef.doc("params"), params);
    batch.set(gameRef.doc("chat"), {global: []});
    batch.set(gameRef.doc("router"), {path: "lobby"});
    const questions = await getQuestions(firestore, totalMoves, questionSetSelected);
    batch.set(gameRef.doc("questions"), questions);
    addGameRefToUser(firestore, batch, gameRef.doc("params"), uid);
    await batch.commit()
    return code;

}

export const joinGame = async (firestore: firebase.firestore.Firestore,
                               code: string,
                               partyName: string,
                               uid: string) => {
    isDebug && console.log('joinGame');
    const gameRef = firestore.collection(code)
    const collection = await gameRef.limit(1).get();
    if (collection.empty) {
        // Game doesn't exist
        throw {field: "code", msg: "Žaidimo su tokiu kodu nėra"};
    }
    const existingPlayer = await gameRef.where("uid", "==", uid).limit(1).get();
    if (!existingPlayer.empty) {
        // Player exists
        throw {field: "code", msg: "Jūs jau esate šiame žaidime"}
    }
    const params = await gameRef.doc("params").get();
    if ((params.data() as ParamsDoc).createdBy === uid) {
        // User is admin of this game
        throw {field: "code", msg: "Jūs jau esate šiame žaidime"}
    }

    /* User is a new player */
    const router = await gameRef.doc("router").get();
    if((router.data() as RouterDoc).path !== "lobby"){
        // Game already started
        // TODO: allow joining mid game
        // NOTE: this is tricky, because path may change any moment.
        throw {field: "code", msg: "Žaidimas jau prasidėjo."}
    }
    const existingParty = await gameRef.where("partyName", "==", partyName).limit(1).get();
    if (!existingParty.empty) {
        // Party name already exists
        throw {field: "partyName", msg: "Partija tokiu pavadinimu jau yra. Sugalvokite kitą."}
    }

    const batch = firestore.batch();
    const player = {
        isPlayer: true,
        partyName: partyName,
        money: 20000,
        moved: false,
        userId: uid,
        score: {
            jaunimas: { points: 0, change: 0, support: 0 },
            miestieciai: { points: 0, change: 0, support: 0 },
            regionai: { points: 0, change: 0, support: 0 },
            senjorai: { points: 0, change: 0, support: 0 },
            totals: { points: 0, change: 0, support: 0 }
        },
        totalsHistory: [] as number[]
    } as PlayerDoc;
    const playerRef = gameRef.doc(partyName);
    batch.set(playerRef, player);
    addGameRefToUser(firestore, batch, gameRef.doc("params"), uid);
    await batch.commit();
}

export const createUserDocument = async (firestore: firebase.firestore.Firestore, uid: string) => {
    isDebug && console.log('createUserDocument');
    await firestore.collection("users").doc(uid).set({games: []});
}

export const getUserGames = async (firestore: firebase.firestore.Firestore, uid: string) => {
    isDebug && console.log('getUserGames');
    const userDoc = await firestore.collection("users").doc(uid).get();
    if (!userDoc.exists) throw Error("User document does not exist.");
    const refsArray = (userDoc.data() as UserDoc).games;
    const gamesPromises = refsArray.map((gameRef) => gameRef.ref.get());
    const gamesDocs = await Promise.all(gamesPromises);
    // In this use case flatMap() acts as many-to-many map, not including non-existing docs.
    return gamesDocs.flatMap(doc => doc.exists ? doc.data() as ParamsDoc : []);

}

export const firestoreStartGame = async (firestore: firebase.firestore.Firestore, code: string) => {
    isDebug && console.log('firestoreStartGame');
    const batch = firestore.batch();
    const gameRef = firestore.collection(code);
    batch.update(gameRef.doc("router"), {path: "game"});
    batch.update(gameRef.doc("params"), {move: 1});
    await batch.commit();
}

export const firestoreNextMove = async (firestore: firebase.firestore.Firestore, code: string) => {
    isDebug && console.log('firestoreNextMove');
    const batch = firestore.batch();
    const gameRef = firestore.collection(code);
    batch.update(gameRef.doc("router"), {path: "game"});
    batch.update(gameRef.doc("params"), {move: firebase.firestore.FieldValue.increment(1)});
    await batch.commit();
}
export const setGameRoute = async (firestore: firebase.firestore.Firestore, code: string, route: GameRoutes) => {
    isDebug && console.log('setGameRoute');
    await firestore.collection(code).doc("router").update({path: route});
}

export const nominate = async (firestore: firebase.firestore.Firestore,
                               code: string, name: string, partyName: string) => {
    isDebug && console.log('nominate');

    const gameRef = firestore.collection(code);
    await gameRef.doc("params").update({
        nominations: firebase.firestore.FieldValue.arrayUnion({name: name, party: partyName})
    });
}
export const removeNomination = async (firestore: firebase.firestore.Firestore,
                               code: string, nomination: {name: string, party: string}) => {
    isDebug && console.log('removeNomination');

    const gameRef = firestore.collection(code);
    await gameRef.doc("params").update({
        nominations: firebase.firestore.FieldValue.arrayRemove(nomination)
    });
}

export const sendChatMessage = async (firestore: firebase.firestore.Firestore, code: string, message: ChatMessage) => {
    isDebug && console.log('sendChatMessage');

    const gameRef = firestore.collection(code);
    await gameRef.doc("chat").update({
        global: firebase.firestore.FieldValue.arrayUnion(message)
    });
}
