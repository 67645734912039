import {PlayerDoc, QuestionDoc} from "../../firebase/firestoreTypes";
import {useFirestore, useFirestoreDocData} from "reactfire";
import Title from "antd/lib/typography/Title";
import NewsHeadings from "../ResultsLayout/NewsHeadings";
import {Button, Card, Divider, Empty, List, Tooltip} from "antd";
import WinnersDisplay from "./WinnersDisplay";
import {sortPlayers, toPercentage} from "../../firebase/utils";
import {SubmitMayorForm} from "../ModalForms";
import {useState} from "react";
import {nominate, removeNomination} from "../../firebase/firestore-api";
import {CloseOutlined} from "@ant-design/icons";
import styles from "./FinalResultsLayout.module.css";

type FinalResultsLayoutProps = {
    players: PlayerDoc[]
    currentPlayer?: PlayerDoc
    isAdmin: boolean
    code: string
    nominations: {name: string, party: string}[]
}

export default function FinalResultsLayout({players, currentPlayer, isAdmin, code, nominations}: FinalResultsLayoutProps) {

    const firestore = useFirestore();
    const sortedPlayers = sortPlayers(players);
    const [nominationVisible, setNominationVisible] = useState(false);
    return (
        <div style={{height: "100%"}}>
            <Title level={2}>Galutiniai Rezultatai</Title>
            <WinnersDisplay sortedPlayers={sortedPlayers}/>
            <List>
                {sortedPlayers.slice(3).map((player, i) => (
                    <List.Item extra={toPercentage(player.score.totals.support, 0)}>
                        <List.Item.Meta
                            avatar={<div>{i + 4}</div>}
                            title={player.partyName}
                            description={"2 mandatai"}
                        />
                    </List.Item>
                ))}
            </List>
            { isAdmin ?
                <div>
                    <Divider>Mero nominacijos</Divider>
                    <div className={styles.grid}>
                        {nominations.map((item, i) => (
                            <div>
                                <Card title={`Partija: ${item.party}`}
                                      extra={
                                          <Tooltip title="Pašalinti">
                                              <Button
                                                  type="text"
                                                  icon={<CloseOutlined/>}
                                                  onClick={() => removeNomination(firestore, code, item)}/>
                                          </Tooltip>
                                      }>
                                    <div className={styles.nominationsNumber}>{`Sąrašo nr. ${i + 1}`}</div>
                                    <div className={styles.nominationsName}>{item.name}</div>
                                </Card>
                            </div>
                        ))}
                    </div>
                </div>
                :
                <Button type="primary" block={true} size="large" style={{marginTop: "1rem"}}
                        onClick={() => setNominationVisible(true)}>
                    Nominuoti merą
                </Button>
            }
            <NewsHeadings players={players}/>
            {currentPlayer ?
                <SubmitMayorForm visible={nominationVisible}
                                 onCreate={async (values) => {
                                     await nominate(firestore, code, values.nomination, currentPlayer.partyName);
                                     setNominationVisible(false);
                                 }}
                                 duplicateWarning={nominations.some(n => n.party === currentPlayer.partyName)}
                                 onCancel={() => setNominationVisible(false)}/> : null }
        </div>
    )
}
