import {Button, message, Modal, Progress} from "antd";
import {PlayerDoc} from "../../firebase/firestoreTypes";
import React, {useEffect, useState} from "react";
import Title from "antd/es/typography/Title";
import styles from "./AdminGameLayout.module.css"
import {backendInterruptMove} from "../../api/api";

type AdminGameLayoutProps = {
    players: PlayerDoc[],
    code: string
}

export default function AdminGameLayout({players, code}: AdminGameLayoutProps) {

    const [finishedPlayers, setFinishedPlayers] = useState<PlayerDoc[]>([]);
    const notify = async (partyName: string) => {
        await message.info(`${partyName} baigė ėjimą!`, 2);
    };
    const [modalVisible, setModalVisible] = useState(false);
    const [modalLoading, setModalLoading] = useState(false);


    useEffect(() => {
        for (const player of players) {
            // If player moved and is not already included
            if (player.moved && !finishedPlayers.some(finishedPlayer => finishedPlayer.userId === player.userId)) {
                setFinishedPlayers(prevState => [...prevState, player]);
                notify(player.partyName);
            }
        }
    }, [players])
    return (
        <div className={styles.parentContainer}>
            <Title level={2}>Partijos renkasi sprendimus...</Title>
            <Progress type="circle"
                      size="default"
                      percent={(finishedPlayers.length / players.length) * 100}
                      format={_ => `${finishedPlayers.length}/${players.length}`}/>
            <Button type="primary" block={true} size="large" onClick={() => setModalVisible(true)}>
                Praleisti ėjimą
            </Button>
            <Modal
                visible={modalVisible}
                title="Ar tikrai norite praleisti šį ėjimą?"
                okText="Praleisti"
                cancelText="Atšaukti"
                centered
                confirmLoading={modalLoading}
                onCancel={() => setModalVisible(false)}
                onOk={async () => {
                    setModalLoading(true);
                    setModalLoading(false);
                    try {
                        await backendInterruptMove(code);
                    } catch (e) {
                        console.error(e);
                    }
                    setModalVisible(false);
                }}>
                <p>Žaidėjai, dar neužbaigę šio ėjimo negaus taškų.</p>
            </Modal>
        </div>
    )
}
